/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { IModule, IModuleTarget } from '../interfaces/modules.interface';
import { User, UserRole } from '../interfaces/user.interface';
import { LoadingController, ToastController } from '@ionic/angular';
import { ITutor } from '../interfaces/schedules.interface';
import { TUTORS } from '../data/schedules.data';
import { PROGRAMS } from '../data/modules.data';
import { Router } from '@angular/router';
import { IProgram } from '../interfaces/db.interface';
import { Student } from '../interfaces/user.interface';
import { STUDENT } from '../data/schedules.data';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  isAdmin = false;
  isStudent = false;
  isTutor = false;

  moduleTargets: IModuleTarget[] = [];
  studentModules: IModule[] = [];
  tutors: ITutor[] = TUTORS;
  student: Student[] = STUDENT;
  campuses = ['Durban', 'Cape Town', 'Johannesburg'];
  programs: any[] = [];

  private currentUser: User;
  constructor(
    private toastController: ToastController,
    private router: Router,
    private loadingCtrl: LoadingController
  ) {}

  get user() {
    return this.currentUser;
  }

  set user(_user: User) {
    this.currentUser = _user;
  }

  // get localUser() {
  //   const user = JSON.parse(localStorage.getItem('user'));
  //   if (!user) {
  //     this.router.navigate(['/login']);
  //   }
  //   this.setRole(user.role);
  //   return;
  // }

  // set localUser(user: User) {
  //   localStorage.setItem('user', JSON.stringify(user));
  //   this.setRole(user.role);
  // }

  async getUser(){
    if (this.currentUser != null){
      return this.currentUser;
    }
    return null;
  }

  async showToast(message: string, title?: string, success?: boolean) {
    const toast = await this.toastController.create({
      header: title ? title : '',
      message,
      duration: 3000,
      color:
        success === true ? 'success' : success === false ? 'danger' : 'primary',
    });
    toast.present();
  }

  getYears(programs: IProgram[], programId: any) {
    if (programId === '') {
      return;
    }
    const i = programs.findIndex((m) => m.id === programId);
    if (i < 0) {
      return;
    }
    const yearCount = programs[i].years + 1;
    const yearVals = [];
    for (let j = 1; j < yearCount; j++) {
      yearVals.push({
        id: j,
        name: `Year ${j}`,
      });
    }
    return yearVals;
  }

  getLessYears(programs: IProgram[], programId: string, curYear: number) {
    return this.getYears(programs, programId).filter((y) => y.id < curYear);
  }

  setRole(role: UserRole) {
    if (role === UserRole.ADMIN) {
      this.isAdmin = true;
    }

    if (role === UserRole.STUDENT) {
      this.isStudent = true;
    }

    if (role === UserRole.TUTOR) {
      this.isTutor = true;
    }
  }

  loader = async (bool: boolean, message?: string) => {
    if (bool) {
      const loading = await this.loadingCtrl.create({
        message,
        showBackdrop: true,
      });
      loading.present();
    } else {
      this.loadingCtrl.dismiss();
    }
  };
}
