import { IMeetingDetails } from "./schedules.interface";

/* eslint-disable @typescript-eslint/naming-convention */
export interface User {
  id: string;
  email?: string;
  name?: string;
  additionalInformation?: Student;
  role: UserRole;
  password?: string;
}

export interface UserAuth {
  user: User;
  passwordHash: string;
}

export enum UserRole {
  STUDENT = 'student',
  ADMIN = 'admin',
  TUTOR = 'tutor',
}

export interface Student {
  studentNo: string;
  programId: string;
  year: string;
  isPaid: boolean;
  subscription?: boolean;
  p1?: boolean;
  p2?: boolean;
  p3?: boolean;
  p4?: boolean;
  p5?: boolean;
  p6?: boolean;
  p7?: boolean;
  subscriptionId: string;
  region: string;
  university: string;
  meetings?: IMeetingDetails[];
}
