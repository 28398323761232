import { Injectable } from "@angular/core";
import * as CryptoJS from 'crypto-js';


@Injectable({
    providedIn: 'root',
  })
  export class encryptDecryptClass {
    //OnlienMeeting!: root;
   encryptSecretToken: string = 'DPICM12345';

    constructor() {}

    encryptData(data: string)  {
        try {
            return CryptoJS.AES.encrypt(data.trim(), this.encryptSecretToken).toString();
          } catch (e) {
            console.log(e);
          }
    }

    decryptData(data) {

        try {
          const bytes = CryptoJS.AES.decrypt(data, this.encryptSecretToken);
          if (bytes.toString()) {
            return bytes.toString(CryptoJS.enc.Utf8);
          }
          return data;
        } catch (e) {
          console.log(e);
        }
      }


}