import { Pipe, PipeTransform } from '@angular/core';
import { DbService } from '../services/db.service';

@Pipe({
  name: 'getCampus',
})
export class GetCampusPipe implements PipeTransform {
  constructor(private dbS: DbService) {}
  async transform(value: any): Promise<string> {
    return (await this.dbS.getCampusById(value)).name;
  }
}


@Pipe({
  name: 'getUserName',
})
export class GetuserNamePipe implements PipeTransform {
  constructor(private dbS: DbService) {}
  async transform(value: any): Promise<string> {
    const name =  (await this.dbS.getUserById(value)).name;

    if(name === undefined){
      return (await this.dbS.getUserById(value)).email;
    }else{
    return (await this.dbS.getUserById(value)).name;
    }
  }
}


@Pipe({
  name: 'getNameByStudentId',
})
export class GetNameByStudentIdPipe implements PipeTransform {
  constructor(private dbS: DbService) {}
  async transform(value: any): Promise<string> {
    return (await this.dbS.getNameByStudentId(value)).name;
  }
}


@Pipe({
  name: 'getProgramNameById',
})
export class GetProgramNameByIdPipe implements PipeTransform {
  constructor(private dbS: DbService) {}
  async transform(value: any): Promise<string> {
    return (await this.dbS.getProgramById(value)).name;
  }
}
