import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import {
  adminMenus,
  studentMenus,
  tutorMenus,
} from 'src/app/config/menu.config';
import { AppService } from 'src/app/services/app.service';
import { DbService } from 'src/app/services/db.service';
import { Router } from '@angular/router';
import { IMeetingDetails, ISchedule, ITutor } from 'src/app/interfaces/schedules.interface';
import { FirebaseService } from 'src/app/services/firebase.service';
import { PATH } from 'src/app/config/path.config';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  appPages;
  nots: any[] = [];
  schedules: ISchedule[] = [];
  list: IMeetingDetails[];
  num = 0;
  constructor(
    public appS: AppService,
    private authS: AuthService,
    private dbS: DbService,
    public router: Router,
    private fireS: FirebaseService,
  ) {}

  async ngOnInit() {
    if (this.appS.isAdmin) {
      this.appPages = adminMenus;
    }
    if (this.appS.isStudent) {
      this.appPages = studentMenus;
    }
    if (this.appS.isTutor) {
      this.appPages = tutorMenus;
      this.appS.tutors =  await this.fireS.getCol(PATH.tutors);
      this.schedules = await this.fireS.getCol(PATH.schedules);
      const tutor = this.appS.tutors
      .find((e) => e.id === this.appS.user.id);
      console.log(tutor);
      this.list = tutor.meetings;
      this.num=this.list.length;
    }
    this.dbS.getUnreadNots(this.appS.user.id).then((res) => (this.nots = res));
  }

  logout() {
    this.authS.logout();
    location.reload();
    //this.router.navigate(['/intro']);
  }
}
