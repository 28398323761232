import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  GetCampusPipe,
  GetuserNamePipe,
  GetNameByStudentIdPipe,
  GetProgramNameByIdPipe,
} from './custom.pipe';

const PIPES = [
  GetCampusPipe,
  GetuserNamePipe,
  GetNameByStudentIdPipe,
  GetProgramNameByIdPipe,
];

@NgModule({
  declarations: [PIPES],
  imports: [CommonModule],
  exports: [PIPES],
})
export class PipesModule {}
