import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {  Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Root } from 'src/app/interfaces/Microsodt interfaces/Teams interfaces/root';
import { ZoomMeeting } from 'src/app/interfaces/zoom-meeting.interface';
import { Mail } from 'src/app/interfaces/Microsodt interfaces/Mail Interface/mail.interface';
import { Auth } from 'src/app/interfaces/Microsodt interfaces/auth.interface';
import 'isomorphic-fetch';
import { ClientSecretCredential } from '@azure/identity';
import { Client, PageCollection } from '@microsoft/microsoft-graph-client';
import { TokenCredentialAuthenticationProvider } from
  '@microsoft/microsoft-graph-client/authProviders/azureTokenCredentials';
import { Slot } from 'src/app/interfaces/study-hours.interface';
import { encryptDecryptClass } from './encrypt-decrypt.service';

  const clientSecretCredential: ClientSecretCredential = new ClientSecretCredential(
    'f8cdef31-a31e-4b4a-93e4-5f571e91255a',
    '5a20757a-04bf-42cc-bbcc-e2eef0affdd8',
    'l368Q~KsnErJiO5XXymaX~F6La2zxwdyHDlPtcJz'
  );
  const appClient: Client | undefined = undefined;
  const GRAPH_ENDPOINT = 'https://api.zoom.us/v2/users/ntokozomweli001@gmail.com/meetings';
  //private ZOOM_TOKEN;

@Injectable({
    providedIn: 'root',
  })
  export class ZoomService {
    //OnlienMeeting!: root;
    private ZOOM_TOKEN: string;


    constructor(private httpClient: HttpClient, private encryptDecrypt: encryptDecryptClass) {}

    async getToken(): Promise<any>{
        
       const token= await this.httpClient.get<any>('https://ae-zoom-api.onrender.com').toPromise();
       //console.log(token);
       const message= token;
       //console.log(token.access_token);
       this.ZOOM_TOKEN = this.encryptDecrypt.encryptData(token.access_token);
       //console.log( this.ZOOM_TOKEN);
       //console.log(this.encryptDecrypt.decryptData(this.ZOOM_TOKEN));
    }

     getZoomToken(): string{
      return this.ZOOM_TOKEN;
    }


}