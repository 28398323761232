// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyBzsYtzD86MLkbIAkHINFGZ4BBUcjDHeig",

  authDomain: "academic-excellence-17c13.firebaseapp.com",

  databaseURL: "https://academic-excellence-17c13-default-rtdb.firebaseio.com",

  projectId: "academic-excellence-17c13",

  storageBucket: "academic-excellence-17c13.appspot.com",

  messagingSenderId: "926634744729",

  appId: "1:926634744729:web:fe448219412888f3f71108",

  measurementId: "G-J7L84C0QF4"

  },

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
