import { Injectable } from '@angular/core';
import { PATH } from '../config/path.config';
import { ICampus, INotification, IProgram, IRegion, IUniversity } from '../interfaces/db.interface';
import { FirebaseService } from './firebase.service';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import { User } from '../interfaces/user.interface';
import { IVideoCourse } from '../interfaces/video.interface';

@Injectable({
  providedIn: 'root',
})
export class DbService {
  constructor(private fireS: FirebaseService, private afs: AngularFirestore) {}

  async getAllCampus(): Promise<ICampus[]> {
    return this.fireS.getCol(PATH.campuses);
  }

  async getCampusById(id: string): Promise<ICampus> {
    return this.fireS.getDoc<ICampus>(PATH.campuses, id);
  }

  addCampus(data: ICampus) {
    return this.fireS.addDoc(PATH.campuses, data);
  }

  updateCampus(data: ICampus) {
    return this.fireS.updateDoc(PATH.campuses, data);
  }

  deleteCampus(data: ICampus) {
    return this.fireS.deleteDoc(PATH.campuses, data.id);
  }

  async getAllUniversity(): Promise<IUniversity[]> {
    return this.fireS.getCol(PATH.universities);
  }

  async getUniversityById(id: string): Promise<IUniversity> {
    return this.fireS.getDoc<IUniversity>(PATH.universities, id);
  }

  addUniversity(data: IUniversity) {
    return this.fireS.addDoc(PATH.universities, data);
  }

  updateUniversity(data: IUniversity) {
    return this.fireS.updateDoc(PATH.universities, data);
  }

  deleteUniversity(data: IUniversity) {
    return this.fireS.deleteDoc(PATH.universities, data.id);
  }

  async getAllRegions(): Promise<IRegion[]> {
    return this.fireS.getCol(PATH.regions);
  }

  async getRegionById(id: string): Promise<IRegion> {
    return this.fireS.getDoc<IRegion>(PATH.regions, id);
  }

  addRegion(data: IRegion) {
    return this.fireS.addDoc(PATH.regions, data);
  }

  updateRegion(data: IRegion) {
    return this.fireS.updateDoc(PATH.regions, data);
  }

  deleteRegion(data: IRegion) {
    return this.fireS.deleteDoc(PATH.regions, data.id);
  }

  async getNots(actor: string) {
    const res = await this.afs
      .collection(PATH.notifications)
      .ref.where('actor', '==', actor)
      .get();
    return res.docs.map((r) => r.data() as INotification);
  }

  async getUnreadNots(actor: string) {
    const res = await this.afs
      .collection(PATH.notifications)
      .ref.where('actor', '==', actor)
      .where('seen', '==', false)
      .get();
    return res.docs.map((r) => r.data() as INotification);
  }

  addNots(title: string, message: string, actor: string) {
    const not: INotification = {
      title,
      message,
      timestamp: Date.now(),
      actor,
      seen: false,
    };
    return this.fireS.addDoc(PATH.notifications, not);
  }

  addNotsMeeting(title: string, message: string, actor: string, dateSet: Date) {
    const not: INotification = {
      title,
      message,
      timestamp: Date.now(),
      actor,
      seen: false,
      date: dateSet,
    };
    return this.fireS.addDoc(PATH.notifications, not);
  }

  deleteNots(id: string) {
    return this.fireS.deleteDoc(PATH.notifications, id);
  }

  async getUserById(id: string): Promise<User> {
    return this.fireS.getDoc<User>(PATH.users, id);
  }

  async getNameByStudentId(studentId: string): Promise<User> {
    const res = await this.afs
      .collection(PATH.users)
      .ref.where('additionalInformation.studentNo', '==', studentId)
      .get();
    return res.docs.map((r) => r.data() as User)[0];
  }

  async getAllPrograms(): Promise<IProgram[]> {
    return this.fireS.getCol(PATH.programs);
  }

  async getProgramById(id: string): Promise<IProgram> {
    return this.fireS.getDoc<IProgram>(PATH.programs, id);
  }

  addProgram(data: IProgram) {
    console.log(data);
    return this.fireS.addDoc(PATH.programs, data);
  }

  updateProgram(data: IProgram) {
    console.log(data);
    return this.fireS.updateDoc(PATH.programs, data);
  }

  deleteProgram(data: IProgram) {
    return this.fireS.deleteDoc(PATH.programs, data.id);
  }

  async fetchVideoCourseByModuleId(moduleId: string): Promise<IVideoCourse> {
    const res = await this.afs
      .collection(PATH.videoCourses)
      .ref.where('moduleId', '==', moduleId)
      .get();
    return res.docs.map((r) => r.data() as IVideoCourse)[0];
  }

  async getAllVideoCoursesByProgramId(programId: string) {
    const res = await this.afs
      .collection(PATH.videoCourses)
      .ref.where('programId', '==', programId)
      .get();
    return res.docs.map((r) => r.data() as IVideoCourse);
  }
}
