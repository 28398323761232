import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {  Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Root } from 'src/app/interfaces/Microsodt interfaces/Teams interfaces/root';
import { ZoomMeeting } from 'src/app/interfaces/zoom-meeting.interface';
import { Mail } from 'src/app/interfaces/Microsodt interfaces/Mail Interface/mail.interface';
import { Auth } from 'src/app/interfaces/Microsodt interfaces/auth.interface';
import 'isomorphic-fetch';
import { ClientSecretCredential } from '@azure/identity';
import { ZoomService } from 'src/app/services/zoom.service';
import { Client, PageCollection } from '@microsoft/microsoft-graph-client';
import { TokenCredentialAuthenticationProvider } from
  '@microsoft/microsoft-graph-client/authProviders/azureTokenCredentials';
import { Slot } from 'src/app/interfaces/study-hours.interface';
import { encryptDecryptClass } from 'src/app/services/encrypt-decrypt.service';

  const clientSecretCredential: ClientSecretCredential = new ClientSecretCredential(
    'f8cdef31-a31e-4b4a-93e4-5f571e91255a',
    '5a20757a-04bf-42cc-bbcc-e2eef0affdd8',
    'l368Q~KsnErJiO5XXymaX~F6La2zxwdyHDlPtcJz'
  );
  const appClient: Client | undefined = undefined;
  const GRAPH_ENDPOINT = 'https://api.zoom.us/v2/users/ntokozomweli001@gmail.com/meetings';

@Injectable({
    providedIn: 'root',
  })
  export class ScheduleService {
    //OnlienMeeting!: root;


    tenant_id='f8cdef31-a31e-4b4a-93e4-5f571e91255a'

    #app
    client_secret='92d6414b-e3c5-4578-bb07-66216f906dca'
    client_id='5a20757a-04bf-42cc-bbcc-e2eef0affdd8'

    #user
    username='ST10119556@vcconnect.edu.za'
    password='DPICM12345'

    private url: string = 'https://graph.microsoft.com/v1.0/'
    public auth_token = "";

    private httpOptions = {
      headers: new Headers({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Authorization' : `Bearer ${this.auth_token}`
      }),
    };
    constructor(private httpClient: HttpClient,
      private zoomService: ZoomService, 
      private encryptDecrypt: encryptDecryptClass) {}

    createMeeting(token: string): Observable<Root> {
      return this.httpClient.post<Root>(this.url + 'me/onlineMeetings',
       {headers: {'Content-Type': 'application/json', Authorization: token}}).pipe(catchError(this.errorHandler));
    }

    
     async createOnlineMeeting(date: any, time: Date, email: string): Promise<any>{
      console.log(this.zoomService.getZoomToken());
      var today = new Date();
      const meeting = { "meetingBody": {
        "agenda": "My Meeting test",
        "default_password": false,
        "password": "AGM65",
        "pre_schedule": false,
        "schedule_for": 'nmweli@proton.me',
        "settings": {
          "additional_data_center_regions": [
            "TY"
          ],
          "allow_multiple_devices": true,
          "alternative_hosts": "",
          "alternative_hosts_email_notification": false,
          "approval_type": 2,
          "approved_or_denied_countries_or_regions": {
            "approved_list": [
              "CX"
            ],
            "denied_list": [
              "CA"
            ],
            "enable": false,
            "method": "approve"
          },
          "audio": "both",
          "audio_conference_info": "test",
          "auto_recording": "cloud",
          "calendar_type": 1,
          "close_registration": false,
          "email_notification": true,
          "encryption_type": "enhanced_encryption",
          "focus_mode": true,
          "host_video": true,
          "jbh_time": 0,
          "join_before_host": true,
          "language_interpretation": {
            "enable": true,
            "interpreters": [
            ]
          },
          "meeting_authentication": true,
          "meeting_invitees": [
            {
              "email": email
            }
          ],
          "mute_upon_entry": false,
          "participant_video": true,
          "private_meeting": false,
          "registrants_confirmation_email": true,
          "registrants_email_notification": true,
          "registration_type": 1,
          "show_share_button": true,
          "use_pmi": false,
          "waiting_room": false,
          "watermark": false,
          "host_save_video_order": true,
          "alternative_host_update_polls": true
        },
        "start_time": time.toISOString(),
        "template_id": "Dv4YdINdTk+Z5RToadh5ug==",
        "timezone": "Africa/Johannesburg",
        "topic": "My Meeting",
        "tracking_fields": [
          {
            "field": "field1",
            "value": "value1"
          }
        ],
        "type": 2
      },
      "token": this.encryptDecrypt.decryptData(this.zoomService.getZoomToken())
      }

       const meetingDetails= await this.httpClient.post<ZoomMeeting>('https://ae-zoom-api.onrender.com/api/meeting', meeting).toPromise();
       console.log(meetingDetails);
       const  message = 'meeting URL: ' + meetingDetails.join_url + '\n start meeting URL: ' +
        meetingDetails.start_url +'\n Meeting ID: ' + meetingDetails.id + '\n password: ' + meetingDetails.password;
       console.log(message);
        return {
          "message": message,
          "meetingId": meetingDetails.id,
          "meetingPassword": meetingDetails.password
        };
   }
   

    async getToken(): Promise<string>{
      const message = {
        client_id: this.client_id,
        scope: 'https%3A%2F%2Fgraph.microsoft.com%2F.default',
        client_secret: this.client_secret,
        grant_type: 'client_credentials',
      };
      const response = await clientSecretCredential.getToken([
        'https://graph.microsoft.com/.default'
      ]);
      return response.token;
      // eslint-disable-next-line max-len
      //return this.httpClient.post<Auth>('https://login.microsoftonline.com/common/oauth2/v2.0/token', message,{headers: {'Content-Type': 'application/x-www-form-urlencoded' }}).pipe(catchError(this.errorHandler));
    }

    getTokenWeb(): Observable<string>{

      return this.httpClient.get<string>('https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=5a20757a-04bf-42cc-bbcc-e2eef0affdd8&response_type=code'+
      '&redirect_uri=https://localhost:8100/schedule-tutor&response_mode=query&scope=https%3A%2F%2Fgraph.microsoft.com%2FOnlineMeetings.ReadWrite.All&state=12345&prompt=none');
    }

    createMessage(id: string, url: string){
      const message = {
        subject: 'Teams Meeting',
        importance: 'High',
        body: {
            contentType: 'HTML',
            content: 'The link to teams meeting!' + url
        },
        toRecipients: [
            {
                emailAddress: {
                    address: id
                }
            }
        ]
      };
      //return this.httpClient.post<Mail>()
      // eslint-disable-next-line max-len
      return this.httpClient.post<Mail>(this.url + 'me/sendMail', JSON.stringify(message), {headers: {'Content-Type': 'application/json',  'Authorization' : `Bearer ${this.auth_token}`}}).pipe(catchError(this.errorHandler));
    }

    errorHandler(error) {
      let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
        // Get client-side error
        errorMessage = error.error.message;
      } else {
        // Get server-side error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      console.log(errorMessage);
      return throwError(() => {
        return errorMessage;
      });
    }
  }