import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { studentMenus } from './config/menu.config';
import { AppService } from './services/app.service';
import { AnimationController, Platform } from '@ionic/angular';
import { register } from 'swiper/element/bundle';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = studentMenus;

  constructor(public router: Router, private appS: AppService,  private animationCtrl: AnimationController,
    ) {
    const user = JSON.parse(localStorage.getItem('user'));
    console.log(user);
    if (user) {
      appS.setRole(user);
      appS.user = user;
      router.navigate(['/home']);
    } else {
      router.navigate(['/intro']);
    } }
}
