import { NgModule } from '@angular/core';
import { NoPreloading, PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ViewSchedulesComponent } from './components/schedules/view-schedules/view-schedules.component';
import { MainComponent } from './layout/main/main.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'intro',
    pathMatch: 'full',
  },
  {
    path: 'intro',
    loadChildren: () =>
      import('./pages/onboarding/onboarding.module').then(
        (m) => m.OnboardingPageModule
      ),
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./auth/auth.module').then((m) => m.AuthPageModule),
  },
  {
    path: 'home',
    component: MainComponent,
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomePageModule),
  },

  {
    path: 'modules',
    component: MainComponent,
    loadChildren: () =>
      import('./pages/modules/modules.module').then((m) => m.ModulesPageModule),
  },
  {
    path: 'study-hours',
    component: MainComponent,
    loadChildren: () =>
      import('./pages/study-hours/study-hours.module').then(
        (m) => m.StudyHoursPageModule
      ),
  },
  {
    path: 'schedule-tutor',
    component: MainComponent,
    loadChildren: () =>
      import('./pages/schedules/schedules.module').then(
        (m) => m.SchedulesPageModule
      ),
  },
  {
    path: 'onboarding',
    loadChildren: () =>
      import('./pages/onboarding/onboarding.module').then(
        (m) => m.OnboardingPageModule
      ),
  },
  {
    path: 'database',
    component: MainComponent,
    loadChildren: () =>
      import('./pages/database/database.module').then(
        (m) => m.DatabasePageModule
      ),
  },
  {
    path: 'notifications',
    component: MainComponent,
    loadChildren: () =>
      import('./pages/notifications/notifications.module').then(
        (m) => m.NotificationsPageModule
      ),
  },
  {
    path: 'videos',
    component: MainComponent,
    loadChildren: () => import('./pages/videos/videos.module').then( m => m.VideosPageModule)
  },
  {
    path: 'payment',
    component: MainComponent,
    loadChildren: () => import('./pages/payment/payment.module').then( m => m.PaymentPageModule)
  },
  {
    path: 'terms',
    component: MainComponent,
    loadChildren: () => import('./pages/terms/terms.module').then( m => m.TermsPageModule)
  },
  {
    path: 'tutor-sch',
    component: MainComponent,
    loadChildren: () => import('./pages/schedules/tutor-sch/tutor-sch.module').then(m => m.TutorSchPageModule)
  },
  {
    path: 'meeting',
    component: MainComponent,
    loadChildren: () => import('./pages/meeting/meeting.module').then( m => m.MeetingPageModule)
  },
  {
    path: 'microsoft',
    component: MainComponent,
    loadChildren: () => import('./pages/microsoft/microsoft.module').then( m => m.MicrosoftPageModule)
  },
  {
    path: 'edit-tutor-profile',
    component: MainComponent,
    loadChildren: () => import('./pages/edit-tutor-profile/edit-tutor-profile.module').then( m => m.EditTutorProfilePageModule)
  },





  // {
  //   path: 'view-schedules',
  //   component: ViewSchedulesComponent
  // }
];
const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: NoPreloading }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
