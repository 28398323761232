export const studentMenus = [
  { title: 'Home', url: '/home', icon: 'home-outline' },
  { title: 'Course Weightings', url: '/modules', icon: 'book-outline' },
  { title: 'Study Hours', url: '/study-hours', icon: 'hourglass-outline' },
  { title: 'Schedule a Tutor', url: '/schedule-tutor', icon: 'school-outline' },
  { title: 'Videos & Content', url: '/videos', icon: 'folder-outline'},
  { title: 'Tutor Sessions', url: '/meeting', icon: 'videocam-outline'},
  {title: 'Payment', url: '/payment', icon: 'cash-outline'},
  {title: 'Login Zoom', url: '/microsoft', icon: 'log-in-outline'},
  {title: 'T&Cs', url: '/terms', icon: 'document-text-outline'},
  {
    title: 'Notifcations',
    url: '/notifications',
    icon: 'notifications-outline',
    notify: true
  },

];

export const adminMenus = [
  { title: 'Home', url: '/home', icon: 'home-outline' },
  //{ title: 'Module', url: '/modules-weightings', icon: 'barbell-outline' },
  { title: 'Modules', url: '/modules', icon: 'book-outline' },
  { title: 'Tutor Schedules', url: '/schedule-tutor', icon: 'school-outline'},
  { title: 'Database', url: '/database', icon: 'albums-outline' },
  { title: 'Videos & Content', url: '/videos', icon: 'videocam-outline'},
];

export const tutorMenus = [
  { title: 'Home', url: '/home', icon: 'home-outline' },
  
  {
    title: 'My Schedule',
    url: '/tutor-sch',
    icon: 'hourglass-outline'
  },
  {title: 'Login Zoom', url: '/microsoft', icon: 'log-in-outline'},
  /*{
    title: 'Notifcations',
    url: '/notifications',
    icon: 'notifications-outline',
    notify: true
  },*/
  {
    title: 'Meeting Requests',
    url: '/schedule-tutor/view-schedules',
    icon: 'school-outline',
    notify: true

  },
  {title: 'T&Cs', url: '/terms', icon: 'document-text-outline'},
  { title: 'Meeting Sessions', url: '/meeting', icon: 'videocam-outline'},
  {
    title: 'Edit Profile',
    url: '/edit-tutor-profile',
    icon: 'person-circle-outline',
  },
];
