/* eslint-disable no-throw-literal */
/* eslint-disable @typescript-eslint/dot-notation */
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { PATH } from '../config/path.config';
import { User, UserRole } from '../interfaces/user.interface';
import { AppService } from '../services/app.service';
import { FirebaseService } from '../services/firebase.service';
import { ModulesService } from '../services/modules.service';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private router: Router,
    private afAuth: AngularFireAuth,
    private fireS: FirebaseService,
    private appS: AppService,
    private moduleS: ModulesService
  ) {}

  async login(email: string, password: string, role: UserRole) {
    try {
      if (role === UserRole.TUTOR) {
        const user = await this.fireS.getTutor<User>(email);
        if (user.role !== role) {
          throw `Incorrect Login Details or Account Not Registered as Tutor`;
        }
         
        if (email != user.email || password != user.password){
          throw `Incorrect Login Details or Account Not Registered as Tutor`;
        }
        this.appS.user = user;
        this.appS.setRole(role);
        console.log(user);
        this.router.navigate(['/home']);

      } else {
        const res = await this.afAuth.signInWithEmailAndPassword(
          email,
          password
        );
        const user = await this.fireS.getDoc<User>(PATH.users, res.user.uid);
        if (user.role !== role) {
          throw `User Role doesn't match account`;
        }
        this.appS.user = user;
        this.appS.setRole(role);
        this.router.navigate(['/home']);
      }
    } catch (error) {
      this.appS.showToast(error, 'Login', false);
    }
  }

  async signup(
    email: string,
    password: string,
    role: UserRole,
    programId?: string,
    year?: string,
    studentNo?: string,
    isPaid?: boolean,
    region?: string,
    university?: string,
  ) {
    try {
      const createdUser = await this.afAuth.createUserWithEmailAndPassword(
        email,
        password
      );
      let user: User;

      if (role === UserRole.STUDENT) {
        user = {
          id: createdUser.user.uid,
          role,
          email,
          additionalInformation: {
            programId,
            year,
            studentNo,
            isPaid: false,
            region,
            university,
            subscriptionId: ' '
          },
        };
      } else {
        user = {
          id: createdUser.user.uid,
          role,
          email,
        };
      }
      await this.fireS.addDoc(PATH.users, user, user.id);
      this.appS.user = user;
      if (role === UserRole.STUDENT) {
        console.log('Generating Student Modules');
        await this.moduleS.generateStudentModules(programId, year, studentNo);
      }
      this.appS.showToast('Account Created Successfully', 'Sign up', true);
      this.appS.user = user;
      this.appS.setRole(role);
      this.router.navigate(['/home']);
    } catch (error) {
      this.appS.showToast(error, 'Sign up', false);
    }
  }


  
  async forgotPassword(email: string){
    const auth = getAuth();
    sendPasswordResetEmail(auth, email).then(() => {
      console.log("123456 Reset email sent")
    }).catch((error) => {
      console.log(error)
    })
  }

  async logout() {
    await this.appS.loader(true, 'Signing Out');
    await this.afAuth.signOut();
    localStorage.removeItem('user');
    this.appS.showToast('Logout Successful', 'Logout', true);
    this.appS.loader(false);
    this.router.navigate(['/intro']);
  }
}
