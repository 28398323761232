/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/dot-notation */
import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { PATH } from '../config/path.config';
import { User } from '../interfaces/user.interface';
import { arrayUnion, SetOptions, } from '@angular/fire/firestore';
import { ITutor } from '../interfaces/schedules.interface';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  constructor(
    private afs: AngularFirestore,
    private afBS: AngularFireStorage,
  ) {}

  async getCol(path: string): Promise<any[]> {
    const res = await this.afs.collection(path).get().toPromise();
    return res.docs.map((d) => d.data());
  }

  async getDoc<Type>(path: string, id: string) {
    const doc = await this.afs.collection(path).doc(id).get().toPromise();
    return doc.data() as Type;
  }

  async getStudent<Student>(sID: string): Promise<Student>{
    const doc = await this.afs.collection(PATH.users).doc(sID).get().toPromise();
    return doc.data() as Student;
  }

  async getTutor<Type>( email: string) {
    const doc = await this.afs.collection(PATH.users).ref.where('email','==', email).get();
    return doc.docs.map(d => d.data() as User)[0];
  }

  async getCurrentTutor<ITutor>( id: string) {
    const doc = await this.afs.collection(PATH.tutors).doc(id).get().toPromise();
    return doc.data() as ITutor;
  }

  /*async getCurrentTutor<Type>( email: string) {
    const doc = await this.afs.collection(PATH.tutors).ref.where('email','==', email).get();
    return doc.docs.map(d => d.data() as ITutor)[0];
  }*/

  addDoc(path: string, data: any, id?: string) {
    if (id) {
      data.id = id;
      return this.afs.collection(path).doc(id).set(data);
    }
    const _id = `${Date.now()}`;
    data['id'] = _id;
    return this.afs.collection(path).doc(_id).set(data);
  }

  addDocArray(path: string, data: any, id?: string) {
    if (id) {
      data.id = id;
      return this.afs.collection(path).doc(id).update(data);
    }
    const updates = new Map();
    updates.set('schedules',data);
    const updateRef = this.afs.collection(path).doc( data['tutorId']);

    return this.afs.collection(path).doc(data['tutorId']).set(arrayUnion(updates));
  }

  async updateDoc<Type>(path: string, data: Type) {
    console.log(data);
    if (!data['id']) {
      // eslint-disable-next-line no-throw-literal
      throw 'Data not found';
    }

    return await this.afs.collection(path).doc(data['id']).update(data);
  }

  async updateMeeting<Type>(path: string, tutID: string, data: Type) {
    if(!data['id']){
      throw 'Data not found';
    }
    return await this.afs.collection(path).doc(tutID).update({meetings: data});
  }

  deleteDoc(path: string, id: string) {
    this.afs.collection(path).doc(id).delete();
  }

  deleteFile(url: string){
    this.afBS.storage.ref(url).delete();
  }

  paginateCol() {}

  async uploadFile(dataUrl: any) {
    const d = Date.now();
    const filePath = `images/${d}`;
    const task = this.afBS.ref(filePath).putString(dataUrl, 'data_url');
    return (await task).ref.getDownloadURL();
  }

  async uploadFiles(dataUrl: any) {
    const d = Date.now();
    const filePath = `${d}`;
    const task = this.afBS.ref(filePath).putString(dataUrl, 'data_url');
    return (await task).ref.getDownloadURL();
  }

  async uploadBlob(file:  File ) {
      //const randomId = Math.random().toString(36).substring(2);
     // const refd = this.afBS.ref(randomId);
      const d = Date.now();
      const filePath = `images/${file.name}`;
      const metadata = {
        contentType: file.type
      };
      //const uploadTaskSnapShot = await this.afBS.ref(filePath).putString(file, metadata);
      const uploadTaskSnapShot = await this.afBS.upload(filePath, file);
      //const url: string = await uploadTaskSnapShot.ref.getDownloadURL();
      return (await uploadTaskSnapShot).ref.getDownloadURL();
  }
}
