export const PATH = {
  modules: 'modules',
  studentModules: 'studentModules',
  studentModuleWeightings: 'studentModuleWeightings',
  users: 'users',
  tutors: 'tutors',
  schedules: 'schedules',
  studyHours: 'study-hours',
  campuses: 'campuses',
  notifications: 'notifications',
  programs: 'programs',
  videoCourses: 'video_courses',
  universities: 'universities',
  regions: 'regions',
  tutorsch: 'tutor_sch',
  student: 'student'
};
