import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import { PATH } from '../config/path.config';
import {
  IModule,
  IModuleTarget,
  IModuleWeight,
  StudentModule,
  StudentModuleWeight,
} from '../interfaces/modules.interface';
import { AppService } from './app.service';
import { FirebaseService } from './firebase.service';
@Injectable({
  providedIn: 'root',
})
export class ModulesService {
  constructor(
    private afs: AngularFirestore,
    private fireS: FirebaseService,
    private appS: AppService
  ) {}

  async getStudentModules(studentNo: string, year: string) {
    return (
      await this.afs
        .collection(PATH.studentModules)
        .ref.where('studentNo', '==', studentNo)
        .where('year', '==', year)
        .get()
    ).docs.map((d) => d.data() as StudentModule);
  }

  async getModules() {
    return (
      await this.afs
        .collection(PATH.modules)
        .ref.get()
    ).docs.map((d) => d.data() as IModule);
  }


  async getModulesByProgram(programId: string) {
    return (
      await this.afs
        .collection(PATH.modules)
        .ref.where('program', '==', programId)
        .get()
    ).docs.map((d) => d.data() as IModule);
  }

  async getStudentWeights(studentNo: string, year: string) {
    return (
      await this.afs
        .collection(PATH.studentModuleWeightings)
        .ref.where('studentNo', '==', studentNo)
        .where('year', '==', year)
        .get()
    ).docs.map((d) => d.data() as StudentModuleWeight);
  }

  async generateStudentModules(programId: any, year: any, studentNo: string) {
    try {
      console.log(programId, year);
      const weights = await this.getStudentWeights(studentNo, year);
      if (weights.length > 0) {
        return weights;
      }
      const availableModules: IModule[] = (
        await this.afs
          .collection(PATH.modules)
          .ref.where('program', '==', programId)
          .where('year', '==', year)
          .get()
      ).docs.map((d) => d.data() as IModule);
      console.log('Available Modules', availableModules);
      const modTar: IModuleTarget[] = availableModules.map((m, i) => ({
        id: `${m.id}_${i}`,
        moduleId: m.id,
        name: m.name,
        code: m.code,
        test1: null,
        test2: null,
        iceTask: null,
        exam: null,
        targetScore: null,
        finalScore: null,
      }));

      const newStudentModule: StudentModule = {
        id: `${studentNo}_${year}`,
        modules: modTar,
        year,
        studentNo,
      };

      console.log('New Module for Student', newStudentModule);

      await this.fireS.addDoc(
        PATH.studentModules,
        newStudentModule,
        newStudentModule.id
      );
    } catch (error) {
      this.appS.showToast(error, null, false);
    }
  }

  async generateWeightings(year: string, programId: string, studentNo: string) {
    try {
      const availableModules: IModule[] = (
        await this.afs
          .collection(PATH.modules)
          .ref.where('program', '==', programId)
          .where('year', '==', year)
          .get()
      ).docs.map((d) => d.data() as IModule);
      console.log(availableModules);
      const weightings: IModuleWeight[] = availableModules.map((m, i) => ({
        id: `${m.id}_${i}`,
        moduleId: m.id,
        name: m.name,
        code: m.code,
        targetScore: null,
        actualScore: null,
      }));

      const newStudentWeight: StudentModuleWeight = {
        id: `${studentNo}_${year}`,
        weightings,
        year,
        studentNo,
      };

      await this.fireS.addDoc(
        PATH.studentModuleWeightings,
        newStudentWeight,
        newStudentWeight.id
      );

      return newStudentWeight;
    } catch (error) {
      this.appS.showToast(error, null, false);
    }
  }
}
